import {useReducer} from "react";
import {createContainer} from "unstated-next";
import ShoppingListData = App.Data.ShoppingListData;

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'ADD_RECIPE':
      return {
        ...state,
        items: [...action.items]
      }
    case 'ADD_ITEM':
      return {
        ...state,
        items: [...state.items, action.item]
      }
    case 'REMOVE_ITEM':
      return {
        ...state,
        items: state.items.filter((item: any) => item.uuid !== action.item.uuid)
      }
    case 'CLEAR_LIST':
      return {
        ...state,
        items: []
      }
    case 'ADD_MEAL_PLAN':
      return {
        ...state,
        items: [...action.items]
      }
    default:
      return state;
  }
}

function useShoppingList(shoppingList?: ShoppingListData) {
  const [
    list,
    dispatchAction
  ] = useReducer(
    reducer,
    {
      items: shoppingList?.items
    }
  );

  return {
    list,
    uuid: shoppingList?.uuid ?? '',
    addRecipe: (items: any) => dispatchAction({type: 'ADD_RECIPE', items}),
    addMealPlan: (items: any) => dispatchAction({type: 'ADD_MEAL_PLAN', items}),
    addItem: (item: any) => dispatchAction({type: 'ADD_ITEM', item}),
    removeItem: (item: any) => dispatchAction({type: 'REMOVE_ITEM', item}),
    clearList: () => dispatchAction({type: 'CLEAR_LIST'})
  }
}

export const ShoppingListService = createContainer(useShoppingList)
