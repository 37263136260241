import './bootstrap';
import '../css/app.css';

import {createRoot} from 'react-dom/client';
import {createInertiaApp} from '@inertiajs/react';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {ShoppingListService} from "@/containers/ShoppingListContainer";
import * as Sentry from "@sentry/react";
import ShoppingListData = App.Data.ShoppingListData;

const appName = import.meta.env.VITE_APP_NAME || 'Pantry Savvy';

Sentry.init({
  dsn: "https://9a4b36acc4814ec896c03ac230a904bd@o338014.ingest.us.sentry.io/4508468002095104",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/pantrysavvy\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) => resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob('./Pages/**/*.tsx')),
  setup({el, App, props}) {
    const root = createRoot(el);
    const pageProps = props.initialPage.props;
    `const auth = pageProps.auth || null;`
    // @ts-ignore
    const shoppingLists: ShoppingListData = pageProps.shoppingList[0];

    root.render(
      <ShoppingListService.Provider initialState={shoppingLists}>
        <App {...props} />
      </ShoppingListService.Provider>
    );
  },
  progress: {
    color: '#26c52d',
  },
});
